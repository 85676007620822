import {
  getShopList,
  getShopStatusInBusiness,
  getUserList,
  getRoleList,
  getShopRoleList,
  getShopRoleDesList,
  addUser,
  updateUser,
  deleteUser,
  userStatus,
  addRole,
  getRoleResList,
  getRoleDes,
  updateRole,
  getUserRoleList,
  getUserDes,
  getRoleAccountList,
  getShopRoleResList,
  addShopRole,
  getShopRoleRes,
  getShopRoleDes,
  updateShopRole,
  deleteShopRole,
  automaticCompletionOfAddress,
  queryAddressDetails,
  getShopInfoByDomain,
  deleteStore,
  getShopInfo,
  reAudit,
} from '@/api/org'

export default {
  namespaced: true,
  state: () => ({
    shopList: [],
    userList: {
      total: 0,
      list: [],
    },
    roleList: {
      total: 0,
      list: [],
    },
    shopRoleList: {
      total: 0,
      list: [],
    },
    shopRoleDesList: [],
  }),
  mutations: {
    setShopList(state, shopList) {
      state.shopList = shopList
    },
    setUserList(state, userList) {
      state.userList = userList
    },
    setRoleList(state, roleList) {
      state.roleList = roleList
    },
    setShopRoleList(state, shopRoleList) {
      state.shopRoleList = shopRoleList
    },
    setShopRoleDesList(state, shopRoleDesList) {
      state.shopRoleDesList = shopRoleDesList
    },
  },
  actions: {
    // 获取店铺列表
    async getShopList(context) {
      const shopList = await getShopList()
      this.commit('org/setShopList', shopList)
      return shopList
    },
    async getShopStatusInBusiness(context) {
      const shopList = await getShopStatusInBusiness()
      return shopList
    },
    // 获取用户列表
    async getUserList(context, params) {
      const userList = await getUserList(params)
      this.commit('org/setUserList', userList)
    },
    // 获取角色列表
    async getRoleList(context, params) {
      const roleList = await getRoleList(params)
      this.commit('org/setRoleList', roleList)
    },
    // 获取店铺列表
    async getShopRoleList(context, params) {
      const shopRoleList = await getShopRoleList(params)
      this.commit('org/setShopRoleList', shopRoleList)
    },
    // 获取店铺角色详情列表
    async getShopRoleDesList(context, params) {
      const shopRoleDesList = await getShopRoleDesList(params)
      this.commit('org/setShopRoleDesList', shopRoleDesList)
    },
    async addUser(context, userParam) {
      await addUser(userParam)
    },
    async updateUser(context, userParam) {
      await updateUser(userParam)
    },
    async deleteUser(context, id) {
      await deleteUser(id)
    },
    async userStatus(context, userParam) {
      await userStatus(userParam)
    },
    async addRole(context, roleParam) {
      await addRole(roleParam)
    },
    async getRoleResList(context) {
      return await getRoleResList()
    },
    async getRoleDes(context, id) {
      return await getRoleDes(id)
    },
    async updateRole(context, roleParam) {
      await updateRole(roleParam)
    },
    async getUserRoleList(context) {
      return await getUserRoleList()
    },
    async getUserDes(context, id) {
      return await getUserDes(id)
    },
    async getRoleAccountList(context, id) {
      return await getRoleAccountList(id)
    },
    async getShopRoleResList(context) {
      return await getShopRoleResList()
    },
    async addShopRole(context, roleParam) {
      await addShopRole(roleParam)
    },
    async getShopRoleDes(context, id) {
      return await getShopRoleDes(id)
    },
    async getShopRoleRes(context, id) {
      return await getShopRoleRes(id)
    },
    async updateShopRole(context, roleParam) {
      await updateShopRole(roleParam)
    },
    async deleteShopRole(context, id) {
      await deleteShopRole(id)
    },
    async automaticCompletionOfAddress(context, params) {
      return await automaticCompletionOfAddress(params)
    },
    async queryAddressDetails(context, params) {
      return await queryAddressDetails(params)
    },
    async getShopInfoByDomain(context, domain) {
      return await getShopInfoByDomain(domain)
    },
    async deleteStore(context, id) {
      return await deleteStore(id)
    },
    async getShopInfo(context, id) {
      return await getShopInfo(id)
    },
    async reAudit(context, data) {
      return await reAudit(data)
    },
  },
}
